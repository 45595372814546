import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { events_all } from '../../../../apis/Events';
import { emplyeeDoc_add } from '../../../../apis/EmployeeDoc';

const AddEmpDoc = () => {
    const [employeese, setEmployees] = useState([]);
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        async function get_data() {
            const result = await events_all();
            setEmployees(result?.data);
        }
        get_data();
    }, []);
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('employee_id', getValues('employee_id'))
        // Append files if available
        if (data.aadhar_card && data.aadhar_card[0]) {
            formData.append('aadhar_card', data.aadhar_card[0]);
        }
        if (data.pan_card && data.pan_card[0]) {
            formData.append('pan_card', data.pan_card[0]);
        }
        if (data.degree && data.degree[0]) {
            formData.append('degree', data.degree[0]);
        }
        if (data.certificate && data.certificate[0]) {
            formData.append('certificate', data.certificate[0]);
        }
        if (data.resume && data.resume[0]) {
            formData.append('resume', data.resume[0]);
        }
        let result = await emplyeeDoc_add(formData);
        if (result.status) {
            return navigate('/admin/emplo-docs');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Add Employee Document</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/employees">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Employees</label>
                                    <select className='select-group form-control' {...register('employee_id', { required: 'Please choose Employees option' })}>
                                        <option value="">Choose Employee</option>
                                        {employeese && employeese?.map((elem) => {
                                            return (
                                                <option value={elem?.id}>{`${elem?.employeeId}-
                                                ${elem?.first_name}  ${elem?.last_name}`}</option>
                                            )
                                        })}
                                    </select>
                                    {errors.employee_id && (
                                        <span className="text-danger">{errors.employee_id.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Aadhar Card</label>
                                    <input type="file" className="form-control" accept=".pdf,image/*"
                                        {...register('aadhar_card', {
                                            required: 'Aadhar card is required',
                                            validate: {
                                                acceptedFormats: (file) =>
                                                    file && ['application/pdf', 'image/jpeg', 'image/png'].includes(file[0]?.type) ||
                                                    'Only PDF or image files are allowed',
                                                fileSize: (file) =>
                                                    file && file[0]?.size <= 2097152 || 'File size must be less than 2MB',
                                            }
                                        })}

                                    />
                                    {errors.aadhar_card && <span className="text-danger">{errors.aadhar_card.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Pan Card</label>
                                    <input type="file" className="form-control" accept=".pdf,image/*"
                                        {...register('pan_card', {
                                            required: 'Pan card is required',
                                            validate: {
                                                acceptedFormats: (file) =>
                                                    file && ['application/pdf', 'image/jpeg', 'image/png'].includes(file[0]?.type) ||
                                                    'Only PDF or image files are allowed',
                                                fileSize: (file) =>
                                                    file && file[0]?.size <= 2097152 || 'File size must be less than 2MB',
                                            }
                                        })}
                                    />
                                    {errors.pan_card && <span className="text-danger">{errors.pan_card.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Degree</label>
                                    <input type="file" className="form-control" name="degree" accept=".pdf,image/*" {...register('degree',
                                        // {
                                        //     validate: {
                                        //         acceptedFormats: (file) =>
                                        //             file && ['application/pdf', 'image/jpeg', 'image/png'].includes(file[0]?.type) ||
                                        //             'Only PDF or image files are allowed',
                                        //         fileSize: (file) =>
                                        //             file && file[0]?.size <= 2097152 || 'File size must be less than 2MB',
                                        //     }
                                        // }
                                    )} />
                                    {errors.degree && <span className="text-danger">{errors.degree.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Certificate</label>
                                    <input type="file" className="form-control" name="certificate"  {...register('certificate',
                                        // {
                                        //     validate: {
                                        //         acceptedFormats: (file) =>
                                        //             file && ['application/pdf', 'image/jpeg', 'image/png'].includes(file[0]?.type) ||
                                        //             'Only PDF or image files are allowed',
                                        //         fileSize: (file) =>
                                        //             file && file[0]?.size <= 2097152 || 'File size must be less than 2MB',
                                        //     }
                                        // }
                                    )}></input>
                                    {errors.certificate && <span className="text-danger">{errors.certificate.message}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Resume</label>
                                    <input type='file' className="form-control" name="resume" {...register('resume',
                                        // {
                                        //     required:false,
                                        //     validate: {
                                        //         acceptedFormats: (file) =>
                                        //             file && ['application/pdf', 'image/jpeg', 'image/png'].includes(file[0]?.type) ||
                                        //             'Only PDF or image files are allowed',
                                        //         fileSize: (file) =>
                                        //             file && file[0]?.size <= 2097152 || 'File size must be less than 2MB',
                                        //     }
                                        // }
                                    )}></input>
                                    {errors.resume && <span className="text-danger">{errors.resume.message}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default AddEmpDoc;
