import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

export async function login_api(data) {
    try {
        const response = await fetch(HTTPURL + 'api/admin-login', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            localStorage.setItem("userData", JSON.stringify(responseData));
            var now = new Date().getTime();
            localStorage.setItem('setupTime', now);
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
