// Data.js
export const columns = [
  {
    name: 'No.',
    selector: 'no',
    sortable: true,
  },
  {
    name: 'EmployeeId',
    selector: 'employeeId',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Joining Date',
    selector: 'joining_date',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
  },
];

export const data = [
  {
    no: 1,
    type: 'Seminar',
    date: '11/05/2023',
    title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
    venue: 'CS Education Castle Hill',
    bookingOffline: '8/22',
    bookingOnline: '-',
    action: 'delete'
  },
  {
    no: 2,
    type: 'Seminar',
    date: '11/05/2023',
    title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
    venue: 'CS Education Castle Hill',
    bookingOffline: '8/22',
    bookingOnline: '-',
    action: 'delete'
  },
  {
    no: 3,
    type: 'Seminar',
    date: '11/05/2023',
    title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
    venue: 'CS Education Castle Hill',
    bookingOffline: '8/22',
    bookingOnline: '-',
    action: 'delete'
  }, {
    no: 4,
    type: 'Seminar',
    date: '11/05/2023',
    title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
    venue: 'CS Education Castle Hill',
    bookingOffline: '8/22',
    bookingOnline: '-',
    action: 'delete'
  }, {
    no: 5,
    type: 'Seminar',
    date: '11/05/2023',
    title: 'OC Application for 2024 & Parent Interview (Castle Hill)',
    venue: 'CS Education Castle Hill',
    bookingOffline: '8/22',
    bookingOnline: '-',
    action: 'delete'
  },
];