import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RequireAuthAdmin, AdminLogout, AfterLogin } from '../Utils/Utils';
import {
  Home, AdminLogin, Layout, Dashboard, Events, AddEmloyee,
  AddProject, AddClient, EditClient, EditEmloyee, EditProject, NewLeaveRequest, EditLeaveRequest,
  AddHoliday, EditHoliday, AddDepartment, EditDepartment, AllHolidays,
  AllProjects, AllLeaveRequest, AllDepartment, AllClient, TodayAttendance, AddPassword, EditPassword, Password,
  AddEmpDoc, EditEmpDoc, AddTemp
} from '../componrnt/pages/Index';

function CreateRoute() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin-login" element={<AfterLogin><AdminLogin /></AfterLogin>} />
        <Route path="/employee-login" element={<AfterLogin><AdminLogin /></AfterLogin>} />

        <Route element={<RequireAuthAdmin />}>
          {/* <Route path="/admin" element={<ProtectedRoute><Layout /></ProtectedRoute>}> */}
          <Route path="/admin" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />

            <Route path="employees" element={<Events />} />
            <Route path="employee/add" element={<AddEmloyee />} />
            <Route path="employee/edit/:id" element={<EditEmloyee />} />

            <Route path="emplo-docs" element={<Events />} />
            <Route path="emplo-doc/add" element={<AddEmpDoc />} />
            <Route path="emplo-doc/edit/:id" element={<EditEmpDoc />} />
            

            <Route path="projects" element={<AllProjects />} />
            <Route path="project/add" element={<AddProject />} />
            <Route path="project/edit/:id" element={<EditProject />} />

            <Route path="attendance/today" element={<TodayAttendance />} />
            <Route path="attendance/employee" element={<AddProject />} />

            <Route path="clients" element={<AllClient />} />
            <Route path="client/add" element={<AddClient />} />
            <Route path="client/edit/:id" element={<EditClient />} />

            <Route path="leave-managements" element={<AllLeaveRequest />} />
            <Route path="leave-management/add" element={<NewLeaveRequest />} />
            <Route path="leave-management/edit/:id" element={<EditLeaveRequest />} />

            <Route path="holidays" element={<AllHolidays />} />
            <Route path="holiday/add" element={<AddHoliday />} />
            <Route path="holiday/edit/:id" element={<EditHoliday />} />

            <Route path="departments" element={<AllDepartment />} />
            <Route path="department/add" element={<AddDepartment />} />
            <Route path="department/edit/:id" element={<EditDepartment />} />

            <Route path="payroll/payslip" element={<Events />} />
            <Route path="payroll/employee-salary" element={<AddDepartment />} />

            <Route path="passwords" element={<Password />} />
            <Route path="password-add" element={<AddPassword />} />
            <Route path="password-edit/:id" element={<EditPassword />} />

            {/* routes for add templates */}
            <Route path='add-temp' element={<AddTemp />} />
            
          </Route>
          <Route path='logoutAdmin' element={<AdminLogout />} />
          {/* </Route> */}

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default CreateRoute;