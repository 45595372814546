import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomTable from '../CustomTable';
import DataLoader from '../../../Utils/DataLoader';
import { client_destroy, clients_all } from '../../../apis/Client';

const columns = [
  {
    name: 'No.',
    selector: 'no',
    sortable: true,
  },
  {
    name: 'ClinetId',
    selector: 'clinet_id',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Joining Date',
    selector: 'joining_date',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
  },
];

const AllClient = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    async function get_data() {
      const result = await clients_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, clinet_id, first_name, last_name, email, joining_date, phone } = elem;
        return {
          no: index + 1 + '.',
          clinet_id,
          name: first_name + ' '+last_name,
          joining_date,
          phone,
          email,
          action: <><Link className='edit-btn' to={`/admin/client/edit/${id}`}><i class="fas fa-edit"></i></Link><button className="dlt-btn" onClick={() => destroy(id)}><i class="fas fa-trash"></i></button></>
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = (id) => {
      const result = window.confirm("Are you sure you want to delete this client?");
      if (result) {
        client_destroy(id);
      }
      get_data();
    }
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      <div className='row'>
        <div className='col-md-4'>
          <h4 className='modal-title'>Clients List</h4>
        </div>
        <div className='col-md-8'>
          <Link className='ui button float-end' to="/admin/client/add">Add Client</Link>
        </div>
      </div>
      {
        showResults ?
          <CustomTable
            columns={columns}
            data={data}
          />
          :
          <DataLoader />
      }
    </div>
  )
}
export default AllClient;
