import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './features';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';


const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
}
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
});