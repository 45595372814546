import React,{useState} from "react";
import { Outlet , Link} from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import "../../css/admin.css";

const Layout = () => 
{
    const [toggle, setToggle] = useState(false);
    const toggleClass = () => 
    {
		setToggle(!toggle);
	};
    return (
        <div className="dash-backdrop">
            <nav className="sb-topnav navbar navbar-expand navbar-light bg-light justify-content-between">
            <div className="d-flex align-items-start">
                <Link className="navbar-brand ps-3" to="/admin/dashboard" title="HR PORTAL">
                    <h3>HR PORTAL</h3>
                    {/* <img src={Logo} alt="Historical" title="Historical"/> */}
                </Link>
                <div className="side-bar-btn" onClick={() => toggleClass()}>
                    <i className="fa-solid fa-bars"></i>
                </div>
            </div>
            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        {/* <li><Link className="dropdown-item" to="/admin/setting"><i className="fa-solid fa-gear"></i>Settings</Link></li> */}
                        <li><Link to="/logoutAdmin" className="dropdown-item"><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</Link></li>
                    </ul>
                </li>
            </ul>
        </nav>
            <div id="layoutSidenav" className={toggle ? `toggle-full-width` : ''}>
                <Sidebar />
                <div id="layoutSidenav_content">
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </div>
    )
};
export default Layout;