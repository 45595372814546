import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addTemp } from '../../../store/services/template';

const AddTemp = () => {
    const [file, setFile] = useState({ file: '', urlImg: '' });
    const [fileError, setFileError] = useState('');
    const {
        register,
        handleSubmit,
        getValues,
        reset,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // handle image 
    const handleImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file type
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                setFileError('Invalid file type. Please upload a PNG, JPEG, or JPG file.');
                return;
            }

            // Check file size (2 MB limit)
            const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
            if (file.size > maxSize) {
                setFileError('File size exceeds 2 MB. Please upload a smaller file.');
                return;
            }
            setFile({ file: e.target.files[0], urlImg: URL.createObjectURL(e.target.files[0]) });
            // If file is valid
            setFileError('');
            // You can handle the file here (e.g., upload it or store it in state)
            console.log('File is valid:', file);
        }
    }

    const onSubmit = async (data) => {

        if (!file.file) {
            setFileError('File is required.');
            return;
        }
        const form = new FormData();
        form.append('category_id', getValues('category_id'))
        form.append('is_active', getValues('is_active'))
        form.append('file', file.file)
        let result = await dispatch(addTemp(form));
        if (result?.payload?.status) {
            reset();
            setFile({ file: '', urlImg: '' });
            return navigate('/admin/add-temp');
        }
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Add Template</h4>
                    </div>
                    {/* <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/employees">Back</Link>
                    </div> */}
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Category:</label>
                                    <select className='select-group form-control' {...register('category_id', { required: 'Category is required' })}>
                                        <option value="">Choose Category</option>
                                        <option value="1">Architiect</option>
                                        <option value="2">B.Tech</option>
                                        <option value="3">Computer</option>
                                        <option value="4">Designer</option>
                                        <option value="5">Developer</option>
                                        <option value="6">Doctors</option>
                                        <option value="7">Accountant</option>
                                    </select>
                                    {errors.category_id && (
                                        <span className="text-danger">{errors.category_id.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>File:</label>
                                    <input type="file" className="form-control" name="url"
                                        onChange={(e) => handleImage(e)}
                                    />
                                    {fileError && <span className="text-danger">{fileError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label>Status:</label>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="flexRadioDefault1" name="is_active"
                                        {...register('is_active', {
                                            required: 'Status is required',
                                        })} />
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Yes
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" id="flexRadioDefault2" name="is_active"
                                        {...register('is_active', {
                                            required: 'Status is required',
                                        })} />
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        No
                                    </label>
                                </div>
                                {errors.is_active && (
                                    <span className="text-danger">{errors.is_active.message}</span>
                                )}
                            </div>
                            {/* <div className="col-md-6"> */}
                            {file.urlImg !== '' ?
                                (<div className='row col-3'>
                                    <img src={file.urlImg} height="270px" width="100px" />
                                </div>)
                                : ''
                            }
                            {/* </div> */}
                        </div>

                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default AddTemp;
