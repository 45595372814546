import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { Password_add } from '../../../apis/Password';
const AddPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await Password_add(data);
        if (result.status) {
            return navigate('/admin/passwords');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Add Password</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/passwords">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Username</label>
                                    <input type="text" className="form-control" name="user_name"
                                        {...register('user_name', { required: 'Username is required' })}
                                    />
                                    {errors.user_name && (
                                        <span className="text-danger">{errors.user_name.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        {...register('password', {
                                            required: 'Password is required',
                                            minLength: {
                                                value: 15,
                                                message: 'Password must be at least 15 characters long'
                                            },
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{15,}$/,
                                                message: 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
                                            }
                                        })}
                                    />
                                    {errors.password && (
                                        <span className="text-danger">{errors.password.message}</span>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Short Desc</label>
                                    <input type="text" className="form-control" name="description"  {...register('description', { required: 'Short Desc is required' })} />

                                    {errors.description && (
                                        <span className="text-danger">{errors.description.message}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddPassword;
