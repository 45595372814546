import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from 'react-router-dom';
import { client_edit, client_update } from '../../../apis/Client';

const EditClient = () => {
    const [file, setFile] = useState({ file: '', urlImg: '' });
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();
    const fetchData = async () => {
        let res = await client_edit(id);
        setValue('first_name', res?.data[0]?.first_name);
        setValue('email', res?.data[0]?.email);
        setValue('last_name', res?.data[0]?.last_name);
        setValue('joining_date', res?.data[0]?.joining_date);
        setValue('designation', res?.data[0]?.designation);
        setValue('gender', res?.data[0]?.gender);
        setValue('phone', res?.data[0]?.phone);
        setValue('country', res?.data[0]?.country);
        setValue('address', res?.data[0]?.address);
        setValue('website', res?.data[0]?.website);
        setValue('clinet_id', res?.data[0]?.clinet_id);
        setFile({ urlImg: `${res?.data[0]?.profile_pic}` });

    }
    useEffect(() => { fetchData(); }, []);

    const onSubmit = async (data) => {
        const form = new FormData();
        form.append('first_name', getValues('first_name'))
        form.append('last_name', getValues('last_name'))
        form.append('email', getValues('email'))
        form.append('clinet_id', getValues('clinet_id'))
        form.append('designation', getValues('designation'))
        form.append('gender', getValues('gender'))
        form.append('phone', getValues('phone'))
        form.append('country', getValues('country'))
        form.append('website', getValues('website'))
        form.append('joining_date', getValues('joining_date'))
        form.append('address', getValues('address'))
        form.append('profile_pic', file.file || file?.urlImg)
        let result = await client_update(id, form);
        if (result.status) {
            return navigate('/admin/clients');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Edit Client</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" name="first_name"
                                        {...register('first_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" name="last_name"
                                        {...register('last_name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" name="email"  {...register('email')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Id</label>
                                    <input type="text" className="form-control" name="clinet_id"  {...register('clinet_id')} disabled />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Designation</label>
                                    <input type="text" className="form-control" name="designation"  {...register('designation')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Gender</label>
                                    <select className='select-group form-control' {...register('gender')}>
                                        <option value="">Choose gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Telephone</label>
                                    <input type='text' className="form-control" name="phone" {...register('phone')}></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Country</label>
                                    <select className='select-group form-control' {...register('country')}>
                                        <option value="">Choose Country</option>
                                        <option value="0">test1</option>
                                        <option value="1">test2</option>
                                        <option value="2">test3</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Website</label>
                                    <input type='text' className="form-control" name="website" {...register('website')}></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Joining Date</label>
                                    <input type="date" className="form-control" name="joining_date"  {...register('joining_date')} disabled />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input type="text" className="form-control" name="address"
                                        {...register('address')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Upload Client Profile Photo</label>
                                    <input type="file" className="form-control" name="profile_pic"
                                        {...register('profile_pic')}
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                </div>
                            </div>
                        </div>
                        {file.urlImg !== '' ?
                            (<div className='row col-3'>
                                <img src={file?.file ? file.urlImg : `${file.urlImg}`} height="240px" width="100px" />
                            </div>)
                            : ''
                        }
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditClient;
