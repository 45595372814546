import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { events_add } from '../../../apis/Events';

const AddHoliday = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await events_add(data);
        if (result.status) {
            return navigate('/admin/clients');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Add Holiday</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Title</label>
                                    <input type="text" className="form-control" name="first_name"
                                        {...register('first_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Holiday Type</label>
                                    <select className='select-group form-control' {...register('holiday_type')}>
                                        <option value="">Choose Holiday Type</option>
                                        <option value="Public Holiday">Public Holiday</option>
                                        <option value="Holiday By Company">Holiday By Company</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Holiday Start Date</label>
                                    <input type="date" className="form-control" name="holiday_start_date"  {...register('holiday_start_date')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Holiday End Date</label>
                                    <input type="date" className="form-control" name="holiday_end_date"  {...register('holiday_end_date')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Holiday Details</label>
                                    <input type="text" className="form-control" name="holiday_details"
                                        {...register('holiday_details')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default AddHoliday;
