import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
import "../../css/adminnew.css";
import { dashboard_data } from "../../apis/Common";
import { useEffect, useState } from "react";
import dashboardIMG from '../../image/dashboard.jpg';


const Dashboard = () => {
    const [dashboard, setDashboard] = useState();

    const fetchData = async () => {
        let res = await dashboard_data();
        setDashboard(res?.data);
    }
    useEffect(() => { fetchData(); }, []);
    
    return (
        <div className="right-nav" id="dash-dashboard">
             <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/feather/feather.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/css/vendor.bundle.base.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/vendors/ti-icons/css/themify-icons.css" />
            <link rel="stylesheet" type="text/css" href="https://themewagon.github.io/skydash/js/select.dataTables.min.css" />
            <link rel="stylesheet" href="https://themewagon.github.io/skydash/css/vertical-layout-light/style.css" />

            <div className="container-fluid">
                <div className="container-scroller">
                    <div className="content-wrapper">
                        <div className="row">
                            <div className="col-md-12 grid-margin">
                                <div className="row">
                                    <div className="col-12 col-xl-8 mb-4 mb-xl-0">
                                        <h3 className="font-weight-bold">HR PORTAL, Welcome Admin</h3>
                                        <h6 className="font-weight-normal mb-0">All systems are running smoothly!</h6>
                                    </div>
                                    <div className="col-12 col-xl-4">
                                        <div className="justify-content-end d-flex">
                                            <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                                                <button className="btn btn-sm btn-light bg-white dropdown-toggle" type="button" id="dropdownMenuDate2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                    <i className="mdi mdi-calendar"></i> Today ({new Date()?.toISOString()?.slice(0,10)})
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuDate2">
                                                    <a className="dropdown-item" href="#">January - March</a>
                                                    <a className="dropdown-item" href="#">March - June</a>
                                                    <a className="dropdown-item" href="#">June - August</a>
                                                    <a className="dropdown-item" href="#">August - November</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 grid-margin stretch-card">
                                <div className="card tale-bg">
                                    <div className="card-people mt-auto">
                                        <img src={dashboardIMG} alt="dashboard.jpeg" />
                                        <div className="weather-info">
                                            <div className="d-flex">
                                                <div>
                                                    <h2 className="mb-0 font-weight-normal"><i className="icon-sun mr-2"></i>31<sup>C</sup></h2>
                                                </div>
                                                <div className="ml-2">
                                                    <h4 className="location font-weight-normal">Delhi</h4>
                                                    <h6 className="font-weight-normal">India</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 grid-margin transparent">
                                <div className="row">
                                    <div className="col-md-6 mb-4 stretch-card transparent">
                                        <div className="card card-tale">
                                            <div className="card-body">
                                                <p className="mb-4">Projects</p>
                                                <p className="fs-30 mb-2">{dashboard?.projects?.employees_count}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-4 stretch-card transparent">
                                        <div className="card card-dark-blue">
                                            <div className="card-body">
                                                <p className="mb-4">Employees</p>
                                                <p className="fs-30 mb-2">{dashboard?.employees?.employees_count}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                                        <div className="card card-light-blue">
                                            <div className="card-body">
                                                <p className="mb-4">Clients </p>
                                                <p className="fs-30 mb-2">{dashboard?.clients?.employees_count}</p>
                                                {/* <p>2.00% (30 days)</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 stretch-card transparent">
                                        <div className="card card-light-danger">
                                            <div className="card-body">
                                                <p className="mb-4">Departments</p>
                                                <p className="fs-30 mb-2">{dashboard?.departments?.employees_count}</p>
                                                {/* <p>0.22% (30 days)</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   <script src="https://themewagon.github.io/skydash/vendors/js/vendor.bundle.base.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/chart.js/Chart.min.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/datatables.net/jquery.dataTables.js"></script>
            <script src="https://themewagon.github.io/skydash/vendors/datatables.net-bs4/dataTables.bootstrap4.js"></script>
            <script src="https://themewagon.github.io/skydash/js/dataTables.select.min.js"></script>
            <script src="https://themewagon.github.io/skydash/js/off-canvas.js"></script>
            <script src="https://themewagon.github.io/skydash/js/hoverable-collapse.js"></script>
            <script src="https://themewagon.github.io/skydash/js/template.js"></script>
            <script src="https://themewagon.github.io/skydash/js/settings.js"></script>
            <script src="https://themewagon.github.io/skydash/js/todolist.js"></script>
            <script src="https://themewagon.github.io/skydash/js/dashboard.js"></script>
            <script src="https://themewagon.github.io/skydash/js/Chart.roundedBarCharts.js"></script> 
  
        </div>
    )
};

export default Dashboard;