import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { events_add } from '../../../apis/Events';

const NewLeaveRequest = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        let result = await events_add(data);
        if (result.status) {
            return navigate('/admin/clients');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">New Leave Request</h4>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>First Name</label>
                                    <input type="text" className="form-control" name="first_name"
                                        {...register('first_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Last Name</label>
                                    <input type="text" className="form-control" name="last_name"
                                        {...register('last_name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Employee Id</label>
                                    <input type="text" className="form-control" name="employee_id"  {...register('employee_id')} />
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Leave Type</label>
                                    <select className='select-group form-control' {...register('leave_type')}>
                                        <option value="">Choose Leave Type</option>
                                        <option value="Privilege Leave">Privilege Leave</option>
                                        <option value="Casual Leave">Casual Leave</option>
                                        <option value="Sick Leave">Sick Leave</option>
                                        <option value="Maternity Leave">Maternity Leave</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input type="date" className="form-control" name="from_date"  {...register('from_date')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input type="date" className="form-control" name="to_date"  {...register('to_date')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Remark</label>
                                    <input type="text" className="form-control" name="remark"
                                        {...register('remark')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default NewLeaveRequest;
