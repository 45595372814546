import React, { useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { project_add } from '../../../apis/Project';

const AddProject = () => {
    const [file, setFile] = useState();
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const form = new FormData();
        form.append('project_name', getValues('project_name'))
        form.append('client_name', getValues('client_name'))
        form.append('client_email', getValues('client_email'))
        form.append('start_date', getValues('start_date'))
        form.append('end_date', getValues('end_date'))
        form.append('team_size', getValues('team_size'))
        form.append('project_documents', file)
        let result = await project_add(form);
        if (result.status) {
            return navigate('/admin/projects');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Add Project</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/projects">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Project Name</label>
                                    <input type="text" className="form-control" name="project_name"
                                        {...register('project_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Name</label>
                                    <input type="text" className="form-control" name="client_name"
                                        {...register('client_name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Client Email</label>
                                    <input type="email" className="form-control" name="client_email"  {...register('client_email')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Start Date</label>
                                    <input type="date" className="form-control" name="start_date"  {...register('start_date')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>End Date</label>
                                    <input type="date" className="form-control" name="end_date"  {...register('end_date')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Team Size</label>
                                    <input type="text" className="form-control" name="team_size"  {...register('team_size')} />
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Upload Project Documents</label>
                                    <input type="file" className="form-control" name="project_documents"
                                        {...register('project_documents')}
                                        onChange={(e) => setFile(e.target.files[0])}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddProject;
