import React, { useEffect, useState } from 'react';
import { columns } from './Data.js';
import { Link } from 'react-router-dom';
import { events_all, events_destroy } from '../../../apis/Events.jsx';
import DataLoader from '../../../Utils/DataLoader.jsx';
import CustomTable from '../CustomTable.jsx';

const Events = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    async function get_data(){
      const result = await events_all();
      const arrayData = result?.data?.map((elem, index) => {
        const {id, first_name, email, last_name, joining_date,gender,phone, employeeId,designation} = elem;
        return {
          no: index + 1 + '.',
          name: first_name +' ' +last_name,
          email,
          joining_date,
          phone,
          employeeId: employeeId ? employeeId : 'N/A',
          action: <><Link className='edit-btn' to={`/admin/employee/edit/${id}`}><i class="fas fa-edit"></i></Link><button className="dlt-btn" onClick={() => destroy(id)}><i class="fas fa-trash"></i></button></>
        };
      });

      
      setData(arrayData, setShowResults(true));
    }
    const destroy = (id) => 
    {
      const result = window.confirm("Are you sure you want to delete this employee?");
      if (result) {
        events_destroy(id);
      }
      get_data();
    }
    get_data();
  }, []);

  return (
    <div className="right-nav" id="dash-event">
      <div className='row'>
          <div className='col-md-4'>
              <h4 className='modal-title'>Employees List</h4>
          </div>
          <div className='col-md-8'>
              <Link className='ui button float-end' to="/admin/employee/add">Add Employee</Link>
          </div>
      </div>
      {
        showResults ?
            <CustomTable
              columns={columns}
              data={data}
            />
          :
          <DataLoader />
      }
    </div>
  )
}
export default Events;
