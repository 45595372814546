import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

export async function clientt_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-client', {
            method: "POST",
            body:data,
            redirect:'follow'
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all clients
export async function clients_all() {
    try {
        const response = await fetch(HTTPURL + 'api/all-clients');
        const responseData = await response.json();
        if(responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// client edit 
export async function client_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/edit-client?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// client update 
export async function client_update(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/update-client/?id=${id?.id}`, {
            method: "POST",
            body:data,
            redirect:'follow'
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// client delete
export async function client_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/destroy-client?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
