import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
    // accordion-header active 
    const [tabActive, setTabActive] = useState({
        employees: false,
        projects: false,
        attendance:false,
        clients: false,
        leaveManagement: false,
        holidays:false,
        departments: false,
        payroll: false,
        password:false,
    });
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('employees')) {
            setTabActive(({ employees: true }));
        }
        if (currentPath.includes('projects')) {
            setTabActive(({ projects: true }));
        }
        if (currentPath.includes('attendance')) {
            setTabActive(({ attendance: true }));
        }
        if (currentPath.includes('clients')) {
            setTabActive(({ clients: true }));
        }
        if (currentPath.includes('leave-managements')) {
            setTabActive(({ leaveManagement: true }));
        }
        if (currentPath.includes('holidays')) {
            setTabActive(({ holidays: true }));
        }
        if (currentPath.includes('departments')) {
            setTabActive(({ departments: true }));
        }
        if (currentPath.includes('payroll')) {
            setTabActive(({ payroll: true }));
        }
    }, [location]);

    return (
        <div id="layoutSidenav_nav">
            <div className="left-main-heading">
                <Link to="/admin/dashboard"><i className="fa-solid fa-table-cells-large"></i> Dashboard</Link>
            </div>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.employees ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#one" aria-expanded="false" aria-controls="one">
                        <i class="fa-solid fa-user"></i> Employees
                        </button>
                    </h2>
                    <div id="one" className={`accordion-collapse collapse ${tabActive?.employees ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul> <Link to="/admin/employees" className="nav-link">All Employees</Link></ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.employees ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#one1" aria-expanded="false" aria-controls="one1">
                        <i class="fa-solid fa-user"></i> Employees Documents
                        </button>
                    </h2>
                    <div id="one1" className={`accordion-collapse collapse ${tabActive?.employees ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul> <Link to="/admin/emplo-doc/add" className="nav-link">Employees Documents</Link></ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.projects ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#two" aria-expanded="false" aria-controls="two">
                        <i class="fa-solid fa-tarp"></i> Projects
                        </button>
                    </h2>
                    <div id="two" className={`accordion-collapse collapse ${tabActive?.projects ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul>
                                    <Link to="/admin/projects" className="nav-link">All Projects</Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.attendance ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#three" aria-expanded="false" aria-controls="three">
                            <i className="fa-solid fa-calendar-days"></i> Attendance
                        </button>
                    </h2>
                    <div id="three" className={`accordion-collapse collapse ${tabActive?.attendance ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link to="/admin/attendance/today" className="nav-link">Today Attendance</Link>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.clients ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="four">
                        <i class="fa-solid fa-users"></i> Clients
                        </button>
                    </h2>
                    <div id="four" className={`accordion-collapse collapse ${tabActive?.clients ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul>
                                    <Link to="/admin/clients" className="nav-link">All Clients</Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.leaveManagement ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#five" aria-expanded="false" aria-controls="five">
                        <i class="fa-solid fa-tarp"></i> Leave Management
                        </button>
                    </h2>
                    <div id="five" className={`accordion-collapse collapse ${tabActive?.leaveManagement ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul>
                                    <Link to="/admin/leave-managements" className="nav-link">All Leave Management</Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.holidays ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#six" aria-expanded="false" aria-controls="six">
                        <i class="fa-solid fa-bell-slash"></i> Holidays
                        </button>
                    </h2>
                    <div id="six" className={`accordion-collapse collapse ${tabActive?.holidays ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul>
                                    <Link to="/admin/holidays" className="nav-link">All Holidays</Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.departments ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seven" aria-expanded="false" aria-controls="seven">
                        <i class="fa-solid fa-building"></i> Departments
                        </button>
                    </h2>
                    <div id="seven" className={`accordion-collapse collapse ${tabActive?.departments ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <Link to="/admin/departments" className="nav-link">All Departments</Link>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.payroll ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nine" aria-expanded="false" aria-controls="nine">
                        <i class="fa-regular fa-credit-card"></i> Payroll
                        </button>
                    </h2>
                    <div id="nine" className={`accordion-collapse collapse ${tabActive?.payroll ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul>
                                    <Link to="/admin/payroll/payslip" className="nav-link">PaySlip</Link>
                                    <Link to="/admin/payroll/employee-salary" className="nav-link">Employee Salary</Link>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.password ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ten" aria-expanded="false" aria-controls="ten">
                        <i className="fa fa-key" aria-hidden="true"></i> Password
                        </button>
                    </h2>
                    <div id="ten" className={`accordion-collapse collapse ${tabActive?.password ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul>
                                    <Link to="/admin/passwords" className="nav-link">Password</Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className={`accordion-header ${tabActive?.password ? 'active' : ''}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#eleven" aria-expanded="false" aria-controls="eleven">
                        <i className="fa fa-key" aria-hidden="true"></i> Templates
                        </button>
                    </h2>
                    <div id="eleven" className={`accordion-collapse collapse ${tabActive?.password ? 'show' : ''}`} data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <nav className="sb-sidenav-menu-nested nav">
                                <ul>
                                    <Link to="/admin/add-temp" className="nav-link">Add Template</Link>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="sb-sidenav-footer"  >
                    <div className="small">Logged in as:</div>
                    HR ADMINs
                </div>
            </div>
        </div>
    )
};
export default Sidebar;