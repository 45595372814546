import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomTable from '../CustomTable';
import DataLoader from '../../../Utils/DataLoader';
import { department_destroy, departments_all } from '../../../apis/Department';

const columns = [
  {
    name: 'No.',
    selector: 'no',
    sortable: true,
  },
  {
    name: 'Department Name',
    selector: 'department_name',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Total Employee',
    selector: 'total_employee',
    sortable: true,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: true,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
  },
];

const AllDepartment = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    async function get_data() {
      const result = await departments_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, department_name, head_of_department, phone, email, total_employee} = elem;
        return {
          no: index + 1 + '.',
          department_name,
          name: head_of_department,
          total_employee,
          phone,
          email,
          action: <><Link className='edit-btn' to={`/admin/department/edit/${id}`}><i class="fas fa-edit"></i></Link><button className="dlt-btn" onClick={() => destroy(id)}><i class="fas fa-trash"></i></button></>
        };
      });
      setData(arrayData, setShowResults(true));
    }
    const destroy = (id) => {
      const result = window.confirm("Are you sure you want to delete this department?");
      if (result) {
        department_destroy(id);
      }
      get_data();
    }
    get_data();
  }, []);
  return (
    <div className="right-nav" id="dash-event">
      <div className='row'>
        <div className='col-md-4'>
          <h4 className='modal-title'>Departments List</h4>
        </div>
        <div className='col-md-8'>
          <Link className='ui button float-end' to="/admin/department/add">Add Department</Link>
        </div>
      </div>
      {
        showResults ?
          <CustomTable
            columns={columns}
            data={data}
          />
          :
          <DataLoader />
      }
    </div>
  )
}
export default AllDepartment;
