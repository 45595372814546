import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { events_edit, events_update } from '../../../apis/Events';

const EditEmloyee = () => {
    const [file, setFile] = useState({ file: '', urlImg: '' });

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();

    // handle image 
    const handleImage = (e) => {
        setFile({ file: e.target.files[0], urlImg: URL.createObjectURL(e.target.files[0]) });
    }

    const fetchData = async () => {
        let res = await events_edit(id);
        setValue('first_name', res?.data[0]?.first_name);
        setValue('email', res?.data[0]?.email);
        setValue('last_name', res?.data[0]?.last_name);
        setValue('joining_date', res?.data[0]?.joining_date);
        setValue('designation', res?.data[0]?.designation);
        setValue('gender', res?.data[0]?.gender);
        setValue('phone', res?.data[0]?.phone);
        setValue('employeeId', res?.data[0]?.employeeId);
        setValue('birth_date', res?.data[0]?.birth_date);
        setValue('address', res?.data[0]?.address);
        setFile({ urlImg: `${res?.data[0]?.profile_pic}` });
    }
    useEffect(() => { fetchData(); }, []);

    const onSubmit = async (data) => {
        const form = new FormData();
        form.append('first_name', getValues('first_name'))
        form.append('last_name', getValues('last_name'))
        form.append('email', getValues('email'))
        form.append('joining_date', getValues('joining_date'))
        form.append('designation', getValues('designation'))
        form.append('gender', getValues('gender'))
        form.append('phone', getValues('phone'))
        form.append('birth_date', getValues('birth_date'))
        form.append('address', getValues('address'))
        form.append('profile_pic', file.file || file?.urlImg)
        let result = await events_update(id, form);
        if (result.status) {
            return navigate('/admin/employees');
        }
    }

    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Edit Employee</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/employees">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>employeeId</label>
                                    <input type="text" className="form-control" name="employeeId"
                                        {...register('employeeId')} disabled
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>FirstName</label>
                                    <input type="text" className="form-control" name="first_name"
                                        {...register('first_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>LastName</label>
                                    <input type="text" className="form-control" name="last_name"
                                        {...register('last_name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" name="online_url"  {...register('email')} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Joining Date</label>
                                    <input type="date" className="form-control" name="joining_date"  {...register('joining_date')} disabled />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Desigantion</label>
                                    <input type="text" className="form-control" name="designation"  {...register('designation')}></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Gender</label>
                                    <select className='select-group form-control' {...register('gender')}>
                                        <option value="">Choose gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Telephone</label>
                                    <input type='text' className="form-control" name="phone" {...register('phone')}></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Birth Date</label>
                                    <input type="date" className="form-control" name="birth_date"
                                        {...register('birth_date')} disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input type="text" className="form-control" name="address"
                                        {...register('address')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Upload Your Profile Photo</label>
                                    <input type="file" className="form-control" name="profile_pic"
                                        {...register('profile_pic')}
                                        onChange={(e) => handleImage(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        {file.urlImg !== '' ?
                            (<div className='row col-3'>
                                <img src={file?.file ? file.urlImg : `${file.urlImg}`} height="240px" width="100px" />
                            </div>)
                            : ''
                        }
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}

export default EditEmloyee;
