import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

// dashboard data
export async function dashboard_data() {
    try {
        const response = await fetch(HTTPURL + 'api/dashboard-data', {
            method: "get",
            headers: { "Content-Type": "application/json" },
        });
        const responseData = await response.json();
        if (responseData.status) {
            // toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}




// get all term
export async function get_terms() {
    try {
        const response = await fetch(HTTPURL + 'api/terms-all');
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term edit 
export async function terms_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/term-edit?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term update 
export async function term_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/term-update/?id=${id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// term delete
export async function term_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/term-destroy?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}


// get all days
export async function get_days() {
    try {
        const response = await fetch(HTTPURL + 'api/days-all');
        const responseData = await response.json();
        return responseData;
    } catch (error) {
        return error.message;
    }
}