import React from 'react';
import { Link } from 'react-router-dom';
import image1 from "../../../image/pic1.jpg";
import image2 from "../../../image/pic2.jpg";

const Home = () => {
    return (
        <>
            <div id="layoutAuthentication" className="login-page home" style={{ backgroundImage: `url(${image1})`,backgroundRepeat: "no-repeat", backgroundSize: 'cover' }}>
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <div className="text-center font-weight-light">HR PORTAL</div>
                                        </div>
                                        <div className="card-body">
                                            <div className="card-body text-black">
                                                <div className="d-flex justify-content-center pt-3">
                                                    <Link to="/admin-login" className="btn btn-warning btn-lg ms-2 sap-btn-dark">Admin Login</Link>
                                                    <Link to="/employee-login" className="btn btn-warning btn-lg ms-2 sap-btn-dark">Employee Login</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}
export default Home;
