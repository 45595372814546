import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

export async function Password_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-password', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all password
export async function Password_all() {
    try {
        const response = await fetch(HTTPURL + 'api/all-passwords');
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//password edit 
export async function Password_edit(id) {
    try {
        const response = await fetch(HTTPURL + `api/edit-password?id=${id?.id}`);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

//password update 
export async function Password_update(id, data) {
    try {
        const response = await fetch(HTTPURL + `api/update-password/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// password delete
export async function Password_destroy(id) {
    try {
        const response = await fetch(HTTPURL + `api/destroy-password?id=${id}`);
        const responseData = await response.json();
        if (responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
