import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import 'react-data-table-component-extensions/dist/index.css';

const CustomTable = ({ columns, data}) => {
  const [searchData, setSeacrhData] = useState('');
  const [filterData, setFilterData] = useState([]);

  const onSearch = (value) => {
    setSeacrhData(value);
    const searchRes = data.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilterData(searchRes);
  };

  return (
    <>
      <div className='searc-bar'>
        <input type="search" className="form-control me-2 float-end" placeholder='Search here...' onChange={(e) => onSearch(e.target.value)} />
      </div>
      <DataTable
        noHeader
        columns={columns}
        data={searchData ? filterData : data}
        defaultSortField="id"
        defaultSortAsc={false}
        pagination
        sortMode="multiple"
        highlightOnHover
      />
    </>
  )
}

export default CustomTable;
