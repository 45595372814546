import React, { useEffect, useState } from 'react';
import { project_destroy, projects_all } from '../../../apis/Project';
import { Link } from 'react-router-dom';
import CustomTable from '../CustomTable';
import DataLoader from '../../../Utils/DataLoader';

const columns = [
  {
    name: 'No.',
    selector: 'no',
    sortable: true,
  },
  {
    name: 'Project Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Client Name',
    selector: 'client_name',
    sortable: true,
  },
  {
    name: 'Client Email',
    selector: 'client_email',
    sortable: true,
  },
  {
    name: 'Project Date',
    selector: 'project_date',
    sortable: true,
  },
  {
    name: 'Team Size',
    selector: 'team_size',
    sortable: true,
  },
  {
    name: 'Action',
    selector: 'action',
    sortable: true,
  },
];

const AllProjects = () => {
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    async function get_data() {
      const result = await projects_all();
      const arrayData = result?.data?.map((elem, index) => {
        const { id, project_name, client_name, client_email, start_date, end_date, team_size } = elem;
        return {
          no: index + 1 + '.',
          name: project_name,
          client_name,
          client_email,
          project_date: start_date + "/" + end_date,
          team_size,
          action: <><Link className='edit-btn' to={`/admin/project/edit/${id}`}><i class="fas fa-edit"></i></Link><button className="dlt-btn" onClick={() => destroy(id)}><i class="fas fa-trash"></i></button></>
        };
      });


      setData(arrayData, setShowResults(true));
    }
    const destroy = (id) => {
      const result = window.confirm("Are you sure you want to delete this project?");
      if (result) {
        project_destroy(id);
      }
      get_data();
    }
    get_data();
  }, []);


  return (
    <div className="right-nav" id="dash-event">
      <div className='row'>
        <div className='col-md-4'>
          <h4 className='modal-title'>Projects List</h4>
        </div>
        <div className='col-md-8'>
          <Link className='ui button float-end' to="/admin/project/add">Add Project</Link>
        </div>
      </div>
      {
        showResults ?
          <CustomTable
            columns={columns}
            data={data}
          />
          :
          <DataLoader />
      }
    </div>
  )
}
export default AllProjects;