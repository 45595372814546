import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HTTPURL } from "../../constants/Matcher";
import { toast } from "react-toastify";

export const addTemp = createAsyncThunk(
    "temp/add-temp",
    async (formData, thunkAPI) => {
        const { rejectWithValue } = thunkAPI;
        try {
            let config = {
                method: 'Post',
                url: `${HTTPURL}api/add-temp `,
                headers: {
                    // "Content-Type": "application/json",
                },
                data: formData
            };
            const res = await axios.request(config);
            if (res?.data?.status) {
                toast.success(res?.data?.message);
            } else {
                toast.error(res?.data?.message);
            }
            return res?.data;
        } catch (error) {
            console.log(error);
        }
    }
);