import React, { useEffect, useState } from 'react';
import { Password_all, Password_destroy } from '../../../apis/Password';
import { Link } from 'react-router-dom';
import CustomTable from '../CustomTable';
import DataLoader from '../../../Utils/DataLoader';

const columns = [
    {
        name: 'No.',
        selector: 'no',
        sortable: true,
    },
    {
        name: 'Username',
        selector: 'user_name',
        sortable: true,
    },
    {
        name: 'Access Type',
        selector: 'access_type',
        sortable: true,
    },
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
    },
];

const Password = () => {
    const [data, setData] = useState([]);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        async function get_data() {
            const result = await Password_all();
            const arrayData = result?.data?.map((elem, index) => {
                const { id, user_name } = elem;
                return {
                    no: index + 1 + '.',
                    user_name,
                    access_type: "No Access",
                    action: <><Link className='edit-btn' to={`/admin/password-edit/${id}`}><i class="fas fa-edit"></i></Link><button className="dlt-btn" onClick={() => destroy(id)} data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i class="fa-solid fa-eye"></i></button></>
                };
            });
            setData(arrayData, setShowResults(true));
        }
        const destroy = (id) => {
            const result = false;
            if (result) {
                Password_destroy(id);
            }
            // get_data();
        }
        get_data();
    }, []);


    return (
        <>
            <div className="right-nav" id="dash-event">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Password List</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/password-add">Add Password</Link>
                    </div>
                </div>
                {
                    showResults ?
                        <CustomTable
                            columns={columns}
                            data={data}
                        />
                        :
                        <DataLoader />
                }
            </div>

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">View Password</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div className='row'>
                                <form action="">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="text" className="form-control" name="email" placeholder='Enter Your Email Address...' required/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Password;