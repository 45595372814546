import React, { useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Password_update, Password_edit } from '../../../apis/Password';

const EditPassword = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();
    const id = useParams();
    console.log('id', id?.id);
    const navigate = useNavigate();
    const fetchData = async () => {
        let res = await Password_edit(id);
        setValue('user_name', res?.data[0]?.user_name);
        setValue('password', res?.data[0]?.password);
        setValue('description', res?.data[0]?.description);
    }
    useEffect(() => { fetchData(); }, [id]);
    const onSubmit = async (data) => {
        let result = await Password_update(id,data);
        if (result.status) {
            return navigate('/admin/passwords');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Edit Password</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/passwords">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Username</label>
                                    <input type="text" className="form-control" name="user_name"
                                        {...register('user_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Password</label>
                                    <input type="text" className="form-control" name="password"
                                        {...register('password')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Short Desc</label>
                                    <input type="text" className="form-control" name="description"  {...register('description')} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditPassword;
