import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

export async function department_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-department', {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all departments
export async function departments_all() {
    try {
        const response = await fetch(HTTPURL + 'api/all-departments');
        const responseData = await response.json();
        if(responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// department edit 
export async function department_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/edit-department?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// department update 
export async function department_update(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/update-department/?id=${id?.id}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// department delete
export async function department_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/destroy-department?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
