import { toast } from "react-toastify";
import { HTTPURL } from "../constants/Matcher";

export async function project_add(data) {
    try {
        const response = await fetch(HTTPURL + 'api/add-project', {
            method: "POST",
            body:data,
            redirect: 'follow'
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// get all projects
export async function projects_all() {
    try {
        const response = await fetch(HTTPURL + 'api/all-projects');
        const responseData = await response.json();
        if(responseData.status) {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// project edit 
export async function project_edit(id)
{
    try {
        const response = await fetch(HTTPURL + `api/edit-project?id=${id?.id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// project update 
export async function project_update(id, data)
{
    try {
        const response = await fetch(HTTPURL + `api/update-project/?id=${id?.id}`, {
            method: "POST",
            body: data,
            redirect: 'follow'
        });
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}

// project delete
export async function project_destroy(id) 
{
    try {
        const response = await fetch(HTTPURL + `api/destroy-project?id=${id}`);
        const responseData = await response.json();
        if(responseData.status)
        {
            toast.success(responseData?.message);
        } else {
            toast.error(responseData?.message);
        }
        return responseData;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
}
