import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate } from 'react-router-dom';
import { events_add } from '../../../apis/Events';

const AddEmloyee = () => {
    const [file, setFile] = useState({ file: '', urlImg: '' });
    const [fileError, setFileError] = useState('');
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();

    // handle image 
    const handleImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check file type
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
            if (!validTypes.includes(file.type)) {
                setFileError('Invalid file type. Please upload a PNG, JPEG, or JPG file.');
                return;
            }

            // Check file size (2 MB limit)
            const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
            if (file.size > maxSize) {
                setFileError('File size exceeds 2 MB. Please upload a smaller file.');
                return;
            }
            setFile({ file: e.target.files[0], urlImg: URL.createObjectURL(e.target.files[0]) });
            // If file is valid
            setFileError('');
            // You can handle the file here (e.g., upload it or store it in state)
            console.log('File is valid:', file);
        }
    }

    const onSubmit = async (data) => {

        if (!file.file) {
            setFileError('Profile pic is required.');
            return;
        }
        const form = new FormData();
        form.append('first_name', getValues('first_name'))
        form.append('last_name', getValues('last_name'))
        form.append('email', getValues('email'))
        form.append('joining_date', getValues('joining_date'))
        form.append('designation', getValues('designation'))
        form.append('gender', getValues('gender'))
        form.append('phone', getValues('phone'))
        form.append('birth_date', getValues('birth_date'))
        form.append('address', getValues('address'))
        form.append('profile_pic', file.file)
        let result = await events_add(form);
        if (result.status) {
            return navigate('/admin/employees');
        }
    }

    // Custom validation function for birth_date
    const validateBirthDate = (value) => {
        const selectedDate = new Date(value);
        const today = new Date();
        const fifteenYearsAgo = new Date();
        fifteenYearsAgo.setFullYear(today.getFullYear() - 15);
        return selectedDate <= fifteenYearsAgo || 'Birth Date must be at least 15 years in the past';
    };
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Add Employee</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/employees">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>FirstName</label>
                                    <input type="text" className="form-control" name="first_name"
                                        {...register('first_name', {
                                            required: 'First Name is required',
                                            pattern: {
                                                value: /^[A-Za-z]+$/,
                                                message: 'First Name should contain only alphabets',
                                            },
                                        })}
                                    />
                                    {errors.first_name && (
                                        <span className="text-danger">{errors.first_name.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>LastName</label>
                                    <input type="text" className="form-control" name="last_name"
                                        {...register('last_name', {
                                            required: 'Last Name is required',
                                            pattern: {
                                                value: /^[A-Za-z]+$/,
                                                message: 'Last Name should contain only alphabets',
                                            },
                                        })}
                                    />
                                    {errors.last_name && (
                                        <span className="text-danger">{errors.last_name.message}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" name="email"
                                        {...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                message: 'Email is not valid',
                                            },
                                        })} />
                                    {errors.email && (
                                        <span className="text-danger">{errors.email.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Joining Date</label>
                                    <input type="date" className="form-control" name="joining_date"
                                        {...register('joining_date', {
                                            validate: (value) => {
                                                const selectedDate = new Date(value);
                                                const today = new Date();
                                                today.setHours(0, 0, 0, 0); // Set time to start of the day
                                                return selectedDate >= today || 'Joining Date must be today or in the future';
                                            },
                                        })}

                                    />
                                    {errors.joining_date && (
                                        <span className="text-danger">{errors.joining_date.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Desigantion</label>
                                    <input type="text" className="form-control" name="designation"  {...register('designation', { required: 'Desigantion is required' })}></input>
                                    {errors.designation && (
                                        <span className="text-danger">{errors.designation.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Gender</label>
                                    <select className='select-group form-control' {...register('gender', { required: 'Gender is required' })}>
                                        <option value="">Choose gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    {errors.gender && (
                                        <span className="text-danger">{errors.gender.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Telephone</label>
                                    <input type='text' className="form-control" name="phone" {...register('phone', {
                                        required: 'Phone number is required',
                                        pattern: {
                                            value: /^[0-9]{10}$/,
                                            message: 'Phone number must be exactly 10 digits',
                                        },
                                    })}></input>
                                    {errors.phone && (
                                        <span className="text-danger">{errors.phone.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Birth Date</label>
                                    <input type="date" className="form-control" name="birth_date"
                                        {...register('birth_date', {
                                            required: 'Birth Date is required',
                                            validate: validateBirthDate,
                                        })}
                                    />
                                    {errors.birth_date && (
                                        <span className="text-danger">{errors.birth_date.message}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Address</label>
                                    <input type="text" className="form-control" name="address"
                                        {...register('address', { required: 'Address number is required' })}
                                    />
                                    {errors.address && (
                                        <span className="text-danger">{errors.address.message}</span>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Upload Your Profile Photo</label>
                                    <input type="file" className="form-control" name="profile_pic"
                                        onChange={(e) => handleImage(e)}
                                    />
                                    {fileError && <span className="text-danger">{fileError}</span>}
                                </div>
                            </div>
                        </div>
                        {file.urlImg !== '' ?
                            (<div className='row col-3'>
                                <img src={file.urlImg} height="240px" width="100px" />
                            </div>)
                            : ''
                        }
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}
export default AddEmloyee;
