import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function checkToken() {
  var now = new Date().getTime();
  var setupTime = JSON.parse(localStorage.getItem('setupTime'));
  if (setupTime != null) {
    console.log("check user is logged in or not...")
    if (now - setupTime > 60 * 60 * 3000) {
      localStorage.clear();
      localStorage.removeItem("userData");
    }
  }
}

function RequireAuthAdmin() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/admin-login' state={{ from: location }} />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.type === 'Admin') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function AdminLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/admin-login' />
}

function RequireAuthTeacher() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/employee-login' state={{ from: location }} />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.type === 'User') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function TeacherLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/employee-login' />
}

function RequireAuthBranch() {
  checkToken();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let location = useLocation();
  if (!userData) {
    return <Navigate replace to='/branch-login' state={{ from: location }} />
  }
  // Assuming userData has a 'roles' property to represent user roles
  if (userData && userData?.data?.roles === 'branch') {
    return <Outlet />;
  }
  return <p>You are not authorized access this view</p>
}

function BranchLogout() {
  localStorage.clear();
  localStorage.removeItem("userData");
  localStorage.removeItem("setupTime");
  return <Navigate replace to='/branch-login' />
}

const ProtectedRoute = ({ children }) => {
  var token = window.localStorage.getItem("userData");
  token = JSON.parse(token);
  const data = token?.data?.type
  if (!token) {
    if (data == 'Admin') {
      return <Navigate to="/admin-login" />;
    } else if (data == 'User') {
      return <Navigate to="/employee-login" />
    }
  }
  else {
    return children;
  }
};

const AfterLogin = ({ children }) => {
  var admin = window.localStorage.getItem("userData");
  admin = JSON.parse(admin);
  const data = admin?.data?.type
  if (admin) {
    if (data == 'Admin') {
      return <Navigate to="/admin/dashboard" />;
    } else if (data == 'User') {
      return <Navigate to="/admin/dashboard" />;
    } 
  }
  else {
    return children;
  }
};

export { RequireAuthAdmin, AdminLogout, RequireAuthTeacher, TeacherLogout, RequireAuthBranch, BranchLogout, ProtectedRoute, AfterLogin }


