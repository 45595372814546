import React, { useEffect, useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { events_all } from '../../../../apis/Events';
import { emplyeeDoc_edit, emplyeeDoc_update } from '../../../../apis/EmployeeDoc';

const EditEmpDoc = () => {
    const [file, setFile] = useState({
        aadhar_card: '', pan_card: '',
        degree: '', certificate: '',
        resume: ''
    });
    const [employeese, setEmployees] = useState([]);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    const id = useParams();

    const fetchData = async () => {
        let res = await emplyeeDoc_edit(id);
        setTimeout(() => {
            setValue('employee_id', res?.data[0]?.employee_id);
        }, 100);
        setFile({
            aadhar_card: `${res?.data[0]?.aadhar_card}`,
            pan_card: `${res?.data[0]?.pan_card}`,
            degree: `${res?.data[0]?.degree}`,
            certificate: `${res?.data[0]?.certificate}`,
            resume: `${res?.data[0]?.resume}`
        });
    }
    
    useEffect(() => {
        async function get_data() {
            const result = await events_all();
            setEmployees(result?.data);
        }
        fetchData();
        get_data();
    }, []);

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('employee_id', getValues('employee_id'))
        // Append files if available
        if (data.aadhar_card && data.aadhar_card[0]) {
            formData.append('aadhar_card', data.aadhar_card[0]);
        } else {
            formData.append('aadhar_card', file.aadhar_card);
        }
        if (data.pan_card && data.pan_card[0]) {
            formData.append('pan_card', data.pan_card[0]);
        } else {
            formData.append('pan_card', file.pan_card);
        }
        if (data.degree && data.degree[0]) {
            formData.append('degree', data.degree[0]);
        } else {
            formData.append('degree', file.degree);
        }
        if (data.certificate && data.certificate[0]) {
            formData.append('certificate', data.certificate[0]);
        } else {
            formData.append('certificate', file.certificate);
        }
        if (data.resume && data.resume[0]) {
            formData.append('resume', data.resume[0]);
        } else {
            formData.append('resume', file.resume);
        }
        let result = await emplyeeDoc_update(id, formData);
        if (result.status) {
            return navigate('/admin/emplo-docs');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
                <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Edit Employee Document</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/employees">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Employees</label>
                                    <select className='select-group form-control' {...register('employee_id')} disabled>
                                        <option value="">Choose Employee</option>
                                        {employeese && employeese?.map((elem) => {
                                            return (
                                                <option value={elem?.id}>{`${elem?.employeeId}-
                                                ${elem?.first_name}  ${elem?.last_name}`}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Aadhar Card</label>
                                    <input type="file" className="form-control" name="aadhar_card"
                                        {...register('aadhar_card')}
                                    />
                                </div>
                                {
                                    file.aadhar_card !== '' &&
                                    <div>
                                        <img src={file.aadhar_card} type="image/jpeg" width="300" height="200" />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Pan Card</label>
                                    <input type="file" className="form-control" name="pan_card"  {...register('pan_card')} />
                                </div>
                                {
                                    file.pan_card !== '' &&
                                    <div>
                                        <img src={file.pan_card} type="image/jpeg" width="300" height="200" />
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Degree</label>
                                    <input type="file" className="form-control" name="degree"  {...register('degree')} />
                                </div>
                                {
                                    file.degree !== '' &&
                                    <div>
                                        <img src={file.degree} type="image/jpeg" width="300" height="200" />
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Certificate</label>
                                    <input type="file" className="form-control" name="certificate"  {...register('certificate')}></input>
                                </div>
                                {
                                    file.certificate !== '' &&
                                    <div>
                                        <img src={file.certificate} type="image/jpeg" width="300" height="200" />
                                    </div>
                                }
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Resume</label>
                                    <input type='file' className="form-control" name="resume" {...register('resume')}></input>
                                </div>
                                {
                                    file.resume !== '' &&
                                    <div>
                                        <img src={file.resume} type="image/jpeg" width="300" height="200" />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default EditEmpDoc;
