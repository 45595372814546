import React, { useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { events_add } from '../../../apis/Events';
import { department_edit, department_update } from '../../../apis/Department';

const EditDepartment = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm();

    const navigate = useNavigate();
    const id = useParams();
    const fetchData = async () => {
        let res = await department_edit(id);
        setValue('department_name', res?.data[0]?.department_name);
        setValue('head_of_department', res?.data[0]?.head_of_department);
        setValue('phone', res?.data[0]?.phone);
        setValue('email', res?.data[0]?.email);
        setValue('starting_date', res?.data[0]?.starting_date);
        setValue('total_employee', res?.data[0]?.total_employee);
        setValue('more_about_department', res?.data[0]?.more_about_department);
    }
    useEffect(() => { fetchData(); }, []);

    const onSubmit = async (data) => {
        let result = await department_update(id,data);
        if (result.status) {
            return navigate('/admin/departments');
        }
    }
    return (
        <div className="right-nav" id="dash-event">
            <div className="modal-content">
            <div className='row'>
                    <div className='col-md-4'>
                        <h4 className='modal-title'>Edit Department</h4>
                    </div>
                    <div className='col-md-8'>
                        <Link className='ui button float-end' to="/admin/departments">Back</Link>
                    </div>
                </div>
                <div className="modal-body">
                    <Form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Department Name</label>
                                    <input type="text" className="form-control" name="department_name"
                                        {...register('department_name')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Head of Department</label>
                                    <input type="text" className="form-control" name="head_of_department"
                                        {...register('head_of_department')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Phone</label>
                                    <input type="text" className="form-control" name="phone"
                                        {...register('phone')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" name="email"
                                        {...register('email')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Starting Date</label>
                                    <input type="date" className="form-control" name="starting_date"  {...register('starting_date')} disabled/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Total Employee</label>
                                    <input type="text" className="form-control" name="total_employee"  {...register('total_employee')} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>More About Department</label>
                                    <input type="text" className="form-control" name="more_about_department"
                                        {...register('more_about_department')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type='submit' className="btn btn-primary">Submit</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default EditDepartment;
